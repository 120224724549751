<template>
  <sp-card :loading="false">
    <p>欢迎来到 Sixpence Blog，这里是一个个人技术博客网站。评论区可以成为与读者、作家互动的好地方，甚至可以产生刺激的对话——它可以是我们所有人的好地方！为确保您在本网站度过愉快的时光，请务必遵守以下准则：</p>
    <p>要有礼貌——不要对任何人无礼，尊重他人。如果你说的话会让你离线，请不要在这里说。请记住，屏幕后面有一个人，你的话会影响人们。
不要巨魔。我们赞赏并欢迎对这里的作者的批评，但对讨论没有价值或仅仅是侮辱性的评论将无一例外地被删除。
垃圾邮件？再见。我们知道您可能会在家工作赚取数千美元，但是，我们不感兴趣。请不要发帖，否则我们会帮你一把，把你和你的帖子都看上门。
用一句简单的话来说，我们的指导方针可以归结为“不要成为家伙”。如果您不遵守这些准则中的任何一项，我们的版主不仅会删除评论，我们还可能禁止您参与讨论。</p>
    <p>如果您发现任何违反我们准则的评论，请务必“标记”或“标记为垃圾邮件”——我们的版主非常感谢您的支持！</p>
    <p>快乐评论！</p>
  </sp-card>
</template>

<script>
export default {

}
</script>

<style>

</style>